import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';

import Layout from 'components/layout';
import ServicesSection from 'components/Sections/ServicesSection';
import Parallax from 'components/Parallax';
import Footer from 'components/Footer/Footer';
import { GridContainer, GridItem as BaseGridItem } from 'components/Grid';
import { landingPageStyles } from 'styles/index.css';
import { itemsMap } from './home-insurance';

const GridItem = styled(BaseGridItem)`
	border-radius: 10px;
	backdrop-filter: blur(1px);
`;

const Auto = ({
	classes,
	data: {
		autoServicesJson: { title: pageTitle, items },
	},
}) => {
	const realItems = itemsMap(items);
	return (
		<Layout pageTitle={pageTitle}>
			<Parallax filter image={require('assets/img/monument-bg.jpg')}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={10}>
							<h1 className={classes.title}>{pageTitle}</h1>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
					<ServicesSection items={realItems} />
				</div>
			</div>
			<Footer />
		</Layout>
	);
};

Auto.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
};

export default withStyles(landingPageStyles)(Auto);

export const query = graphql`
	query AutoServicesQuery {
		autoServicesJson {
			title
			items {
				title
				items
			}
		}
	}
`;
